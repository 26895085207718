import { createRouter, createWebHashHistory } from 'vue-router';

const routes = [

  {
    name: 'notFound',
    path: '/:path(.*)+',
    redirect: {
      name: 'home',
    },
  },
  {
    name: 'home',
    path: '/home',
    component: () => import('./view/home'),
    meta: {
      title: '',
    },
  },
  {
    name: 'caseGroup',
    path: '/case/group',
    component: () => import('./view/case/group'),
    meta: {
      title: '案例列表',
    },
  }, 
  {
    name: 'caseList',
    path: '/case/list',
    component: () => import('./view/case/list'),
    meta: {
      title: '案例列表',
    },
  }, 
  {
    name: 'caseItem',
    path: '/case/item',
    component: () => import('./view/case/item'),
    meta: {
      title: '案例详情',
    }, 
  },

  {
    name: 'aboutUs',
    path: '/about/us',
    component: () => import('./view/about/us'),
    meta: {
      title: '关于我们',
    },
  }, 
  {
    name: 'aboutContact',
    path: '/about/contact',
    component: () => import('./view/about/contact'),
    meta: {
      title: '联系我们',
    }, 
  },

];

const router = createRouter({
  routes,
  history: createWebHashHistory(),
});

router.beforeEach((to, from, next) => {
  const title = to.meta && to.meta.title;
  if ( title ) {

    document.title = title + ' | 广州品誉装饰设计工程有限公司';
    
  } else {
    document.title = '广州品誉装饰设计工程有限公司';
  }
  
  next();
});

export { router };
